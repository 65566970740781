<template>
  <div class="layout">
    <div v-show="qrCodeRightVisible" class="flex_center_column float_right">
      <a-icon class="f36 mb8" type="cloud-download" />
      <p>下载步骤</p>
      <a-icon class="f20 mt20" type="double-left" />
      <div class="step_block">
        <a-icon type="caret-right" />
        <img class="step_block_img" src="@/assets/step_block.jpg" alt />
      </div>
    </div>
    <!-- 菜单 -->
    <div class="flex_center_between nav">
      <div class="flex left_nav">
        <img class="logo" src="@/assets/layout_logo.jpg" alt="logo" />
        <ul class="flex nav_list">
          <li class="active">首页</li>
          <li>
            使用教程
            <a-icon class="icon_arrow" type="caret-down" />
            <div class="use_list">
              <a-icon class="use_list_icon_arrow" type="caret-up" />
              <ul>
                <li class="flex item" v-for="(item, index) in useList" :key="index">
                  <span class="flex text">{{ item.label }}下载教程</span>
                  <img class="app_icon" :src="require(`@/assets/tools_icon${item.icon}.png`)" alt />
                </li>
              </ul>
            </div>
          </li>
          <li>
            视频工具
            <a-icon class="icon_arrow" type="caret-down" />
          </li>
          <li>商务合作</li>
        </ul>
      </div>
      <div class="right_nav">
        <div class="flex btn" @click="qrCodeVisible = true">
          <p class="flex badge">
            <a-icon type="caret-up" />免费下载
          </p>
          <a-icon class="mr4" type="scan" />扫码即用
        </div>
      </div>
    </div>
    <!-- banner -->
    <div class="flex_center_column banner">
      <div class="text">
        <p>
          <img style="width: 550px;" src="@/assets/title.png" alt />
        </p>
        <p class="mt12">扫码即用、无需安装、支持100+视频平台下载</p>
      </div>
      <div class="qrcode">
        <img src="@/assets/qrcode.jpg" alt />
      </div>
      <div class="btn">
        <a-icon class="mr4" type="scan" />微信扫码立即免费使用
      </div>
      <div class="flex block">
        <div class="flex_center_column step">
          <img class="step_img" src="@/assets/step1.png" alt />
          <p class="step_text">1.微信扫码添加机器人下载助手</p>
        </div>
        <img class="arrow" src="@/assets/person_index_arrow.png" alt />
        <div class="flex_center_column step">
          <img class="step_img" src="@/assets/step2.png" alt />
          <p class="step_text">2.将视频转发或复制链接到下载助手</p>
        </div>
        <img class="arrow" src="@/assets/person_index_arrow.png" alt />
        <div class="flex_center_column step">
          <img class="step_img" src="@/assets/step3.png" alt />
          <p class="step_text">3.点击自动回复的通知下载高清、无压缩视频</p>
        </div>
      </div>
    </div>
    <p class="insert_dir"></p>
    <!-- 内容 -->
    <div class="container">
      <div class="mb150 block">
        <img class="title1" src="@/assets/title1.png" alt />
        <div class="flex letters">
          <div class="words">
            <img class="icon" src="@/assets/icon_error.png" alt />
            <p class="f20 c_fff">传统下载视频</p>
            <p class="mt12 mb40 f16">
              操作繁杂，上手难，步骤多，等待时间长，
              <br />无法解决水印等问题
            </p>
          </div>
          <div class="words">
            <img class="icon" src="@/assets/icon_success.png" alt />
            <p class="f20 c_fff">云大师AI智能去水印</p>
            <p class="mt12 mb40 f16">
              只需三个步骤，您就能轻松下载到无损、高清、无水印的原视频
              <br />还可下载无水印图片
            </p>
          </div>
        </div>
      </div>
      <div class="mb200 block">
        <img class="title2" src="@/assets/title2.png" alt />
        <div class="flex_center_between box">
          <a-carousel effect="fade" autoplay :dots="false">
            <img src="@/assets/tools_block.png" alt />
            <img src="@/assets/tools_block1.png" alt />
            <img src="@/assets/tools_block2.png" alt />
          </a-carousel>
          <div class="tc pr200">
            <p class="f22 c_fff mb8">已支持各大平台</p>
            <p class="f16 lh32">
              视频号/公众号/抖音/快手/拼多多/小红书/今日头条/英语趣配音
              <br />西瓜/微博/知乎/好看视频/皮皮虾/抖音极速版/快手极速版
              <br />等等100多个常见视频平台提取
            </p>
          </div>
        </div>
      </div>
      <div class="mb260 block">
        <img class="title3" src="@/assets/title3.png" alt />
        <div class="bg1"></div>
        <ul class="flex_center_between mt70 bg_block">
          <li>
            <div class="flex_start">
              <span class="step_num">1</span>
              <p class="flex_start_column">
                <span class="f18 c_fff">第一步：扫码识别二维码</span>
                <span class="f16">微信添加企业下载助手</span>
              </p>
            </div>
            <img src="@/assets/qrcode.jpg" alt />
          </li>
          <li>
            <div class="flex_start">
              <span class="step_num">2</span>
              <p class="flex_start_column">
                <span class="f18 c_fff">第二步：转发视频号视频给下载助手</span>
                <span class="f16">点击视频下方分享按钮发给下载助手</span>
              </p>
            </div>
            <img src="@/assets/demo1.jpg" alt />
          </li>
          <li>
            <div class="flex_start">
              <span class="step_num">3</span>
              <p class="flex_start_column">
                <span class="f18 c_fff">第三步：根据提示下载视频</span>
                <span class="f16">点击助手自动返回的通知页面下载</span>
              </p>
            </div>
            <img src="@/assets/demo2.jpg" alt />
          </li>
        </ul>
      </div>
      <!-- 视频下载教程 -->
      <div class="mb200">
        <p class="f46 fw c_fff tc">
          <img class="w300" src="@/assets/title4.png" alt />
        </p>
        <div class="use_list2">
          <ul>
            <li v-for="(item, index) in useList" :key="index">
              <div class="icon">
                <img :src="require(`@/assets/tools_icon${item.icon}.png`)" alt />
              </div>
              <p class="label">{{ item.label }}</p>
            </li>
          </ul>
        </div>
      </div>
      <!-- 常见问题 -->
      <p class="f46 fw c_fff tc mb40">
        <img class="w200" src="@/assets/title5.png" alt />
      </p>
      <div class="question_list">
        <ul>
          <li v-for="(item, index) in questionList" :key="index">
            <p class="title">Q：{{ item.question }}</p>
            <p class="content" v-html="'A：' + item.answer"></p>
          </li>
        </ul>
      </div>
      <!-- 免责声明 -->
      <div class="statement_group">
        <p class="tit">免责声明</p>
        <p
          class="desc"
        >使用云大师视频下载的视频仅用于个人观赏或娱乐使用，不作任何商业用途。任何情况下，视频版权归创作者所有；云大师服务包含的下载资源信息内的所有内容并不反映亦不代表任何云大师软件公司之意见；云大师视频只下载公开视频，加密、VIP、付费等视频不支持下载。</p>
      </div>
    </div>
    <!-- 页脚 -->
    <div class="footer">
      <div class="flex_center_between">
        <div class="left">
          <p class="c_fff f18 lh32">『云大师去水印下载 』</p>
          <p class="c_fff lh32">专业团队开发高品质工具，已持续提供稳定服务4年，超越市面上99%的同款产品！</p>
          <p class="mt30 lh32 f12">为您提供无压缩、无限速的下载工具服务。</p>
          <p class="f12">只需1秒即可在线提取，无需安装，扫一扫即可使用。已有无数用户给予高度评价！</p>
          <p class="mt30 lh32">云大师 让下载视频更轻松！</p>
        </div>
        <div class="flex right">
          <div class="flex_center_column">
            <img class="qrcode" src="@/assets/qrcode_xcx.jpg" alt />
            <p class="text_box">视频下载小程序入口</p>
          </div>
          <div class="flex_center_column">
            <img class="qrcode" src="@/assets/qrcode_gzh.jpg" alt />
            <p class="text_box">公众号了解更多详情</p>
          </div>
          <div class="flex_center_column">
            <img class="qrcode" src="@/assets/qrcode_kf.jpg" alt />
            <p class="text_box">客服微信咨询</p>
          </div>
        </div>
      </div>
      <p class="copyright">
        <a href="https://beian.miit.gov.cn/" target="_blank">渝ICP备2023005310号-1</a>
        <time datetime="2024">© 2024 重庆恰时正好品牌设计有限公司 版权所有</time>
      </p>
    </div>
    <a-modal v-model="qrCodeVisible" width="500px" centered title :footer="null">
      <div class="img_qrcode_dialog">
        <div class="img_qrcode">
          <img src="@/assets/qrcode.jpg" alt />
        </div>
        <p class="tc f16 fw">微信扫码立即免费使用</p>
        <p class="tc mt4">云大师智能去水印支持100+短视频平台</p>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentNav: '0',
      navList: [
        { label: '首页', value: '0' },
        { label: '使用教程', value: '1' },
        { label: '视频工具', value: '2' },
        { label: '商务合作', value: '03' }
      ],
      useList: [
        { label: '视频号', icon: '1' },
        { label: '公众号', icon: '2' },
        { label: '抖音', icon: '3' },
        { label: '快手', icon: '4' },
        { label: '拼多多', icon: '5' },
        { label: '美团', icon: '6' },
        { label: '淘宝', icon: '7' },
        { label: '英语趣配音', icon: '8' },
        { label: '小红书图文', icon: '9' },
        { label: '小红书视频', icon: '9' },
        { label: '微博', icon: '10' },
        { label: '今日头条', icon: '11' },
        { label: 'bilibili', icon: '12' },
        { label: '西瓜视频', icon: '13' },
        { label: '微视', icon: '14' },
        { label: '新浪视频', icon: '15' },
        { label: '好看视频', icon: '16' },
        { label: '皮皮虾', icon: '17' },
        { label: '火山视频', icon: '18' },
        { label: '抖音极速版', icon: '19' },
        { label: 'TikTok', icon: '20' },
        { label: '知乎', icon: '21' },
        { label: 'QQ短视频', icon: '22' },
      ],
      questionList: [
        { question: '怎么复制短视频链接?', answer: '在短视频APP中点击“分享”，然后找到“复制链接”，点击即可复制。' },
        { question: '视频需要下载多久?', answer: '云大师不限速下载，具体需要根据您的网速，视频平台速度等因素决定。' },
        { question: '下载视频版权归谁？', answer: '版权归上传作者所有，仅供个人学习、娱乐之用，不作任何商业用途，违反自行承担责任。' },
        { question: '提示解析失败？', answer: '有可能是掉线导致，需要提示客服；过于偏门不支持；使用方法不对，小程序寻找教程或客服' },
        { question: '是否会跑路？', answer: '不会！已完美运行数年，其他同类产品生命周期短、易跑路，推荐使用本产品' },
        { question: '解析后还有水印?', answer: '解析出来的视频与原视频一致，所以如果原视频本身就带有水印，解析后的也是同样会有水印。' },
      ],
      qrCodeVisible: false,
      qrCodeRightVisible: false
    }
  },
  mounted() {
    document.addEventListener('scroll', (e) => {
      const top = e.target.scrollingElement.scrollTop
      const blockTop = document.querySelector('.banner .block').offsetTop
      this.qrCodeRightVisible = top >= blockTop + 100
    })
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/animate.less';

.layout {
  width: 100vw;
  background: #010915;
  color: #b7bbd4;
  min-width: 1200px;
  overflow-x: hidden;
}

.nav {
  height: 64px;
  padding: 0 56px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-sizing: border-box;
  backdrop-filter: saturate(180%) blur(12px);

  .left_nav {
    height: 100%;

    .logo {
      height: 49px;
    }

    .nav_list {
      margin-left: 30px;
      font-size: 16px;
      height: 100%;

      > li {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 20px;
        height: 100%;
        cursor: pointer;
        transition: all 0.3s;
        position: relative;

        &.active {
          background: rgba(64, 69, 102, 0.6);
          color: #e4e6f3;
        }

        &:hover {
          color: #e4e6f3;

          .icon_arrow {
            margin-left: 2px;
            &:extend(.rotate180-leave-active);
          }

          .use_list {
            display: block;
          }
        }

        .icon_arrow {
          margin-left: 2px;
          &:extend(.rotate180);
          &:extend(.rotate180-enter-active);
        }
      }
    }
  }

  .right_nav {
    .btn {
      width: 112px;
      height: 34px;
      font-size: 14px;
      color: #ffffff;
      border-radius: 8px;
      background: linear-gradient(to right, #123a94, #57298a);
      cursor: pointer;
      position: relative;
      .badge {
        position: absolute;
        bottom: -30px;
        background: #eb465c;
        border-radius: 8px 2px;
        width: 66px;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        .anticon {
          position: absolute;
          top: -8px;
          color: #eb465c;
        }
      }
    }
  }

  .use_list {
    position: absolute;
    left: -40px;
    top: 60px;
    padding-top: 12px;
    display: none;
    .use_list_icon_arrow {
      position: absolute;
      top: 2px;
      left: 85px;
      color: #fff;
    }

    > ul {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      background: #ffffff;
      border-radius: 18px;
      padding: 20px;
      overflow-y: scroll;
      width: 666px;
      height: 500px;

      .item {
        width: 142px;
        height: 80px;
        border-radius: 8px;
        cursor: pointer;
        position: relative;
        &:extend(.translateY10);
        &:extend(.translateY10-enter-active);

        &:hover {
          &:extend(.translateY10-leave-active);
        }

        .text {
          position: relative;
          z-index: 1;
          width: 100%;
          height: 100%;
          font-size: 14px;
          color: #ffffff;
          padding-top: 40px;
          border-radius: 8px;
          background: rgba(0, 0, 0, 0.6);
          backdrop-filter: saturate(180%) blur(2px);
        }

        .app_icon {
          position: absolute;
          z-index: 0;
          height: 48px;
        }
      }
    }
  }
}

.banner {
  width: 100%;
  height: 800px;
  position: relative;
  z-index: 1;
  background: url('../assets/bg.jpg') no-repeat;
  background-size: cover;

  .text {
    text-align: center;
    color: #fff;

    > p {
      &:first-child {
        font-size: 56px;
        font-weight: bolder;
        letter-spacing: 16px;
      }

      &:last-child {
        font-size: 18px;
        letter-spacing: 8px;
      }
    }
  }

  .qrcode {
    background: #ffffff;
    border-radius: 12px;
    padding: 12px;
    margin-top: 80px;

    > img {
      width: 180px;
      height: 180px;
    }
  }

  .btn {
    width: 198px;
    height: 40px;
    background-size: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #ffffff;
    border-radius: 30px;
    margin-top: 24px;
    background: linear-gradient(to right, #123a94, #57298a);

    .anticon.anticon-wechat {
      margin-right: 4px;
    }
  }

  .block {
    width: 1200px;
    height: 188px;
    border: 1px solid rgba(255, 255, 255, 0.8);
    background: rgba(13, 43, 86, 0.8);
    border-radius: 16px;
    position: absolute;
    bottom: -99px;
    .step {
      .step_img {
        height: 64px;
        width: 64px;
        background: #333;
        border-radius: 50%;
        margin-bottom: 16px;
      }
    }

    .arrow {
      width: 27px;
      height: 32px;
      margin: 0 50px;
    }
  }
}

.insert_dir {
  height: 280px;
}

.container {
  width: 1200px;
  margin: 0 auto;

  .block {
    .title1 {
      width: 860px;
      display: block;
      margin: 0 auto;
    }

    .title2 {
      width: 670px;
      display: block;
      margin: 0 auto;
    }

    .title3 {
      width: 520px;
      display: block;
      margin: 0 auto;
    }

    .letters {
      width: 1200px;
      height: 180px;
      margin-top: 70px;
      background: url('../assets/group1.png') no-repeat;
      background-size: contain;

      .words {
        text-align: center;
        width: 50%;
        position: relative;

        &:last-child {
          .icon {
            top: -22px;
            width: 31%;
          }
        }

        .icon {
          position: absolute;
          width: 22%;
          top: -24px;
          left: 39%;
        }
      }
    }

    .box {
      width: 1200px;
      margin-top: 70px;
      background: #0b1f3a;
      border-radius: 24px;

      .ant-carousel {
        width: 380px;
      }

      .words {
        padding-right: 200px;
      }
    }

    .bg1 {
      height: 340px;
      position: absolute;
      left: 0;
      right: 0;
      margin-top: 70px;
      background: url(../assets/bg1.jpg) no-repeat;
      background-size: cover;
      z-index: 0;
    }

    .bg_block {
      padding-top: 70px;
      padding: 70px 60px 0 60px;
      position: relative;
      z-index: 1;

      > li {
        > img {
          border-radius: 8px;
        }

        &:nth-child(1) img {
          padding: 12px;
          background: #fff;
          border-radius: 12px;
        }

        img {
          height: 120px;
          margin-top: 30px;
          margin-left: 50px;
        }

        .step_num {
          flex-shrink: 0;
          background: linear-gradient(#5c1ce8, rgba(255, 255, 255, 0));
          width: 30px;
          height: 30px;
          text-align: center;
          line-height: 30px;
          border-radius: 50%;
          display: inline-block;
          font-weight: 600;
          color: #fff;
          margin-right: 18px;
        }
      }
    }
  }
}

.use_list2 {
  margin-top: 40px;

  > ul {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;

    > li {
      text-align: center;

      .icon {
        width: 180px;
        height: 100px;
        background: rgba(11, 31, 58, 0.3);
        border-radius: 16px;
        border: 2px solid rgba(255, 255, 255, 0.08);
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 72px;
          border-radius: 10px;
        }
      }

      .label {
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
        line-height: 24px;
        margin-top: 12px;
      }
    }
  }
}

.question_list {
  > ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > li {
      width: 49%;
      box-sizing: border-box;
      padding: 16px 24px;
      background: rgba(11, 31, 58, 0.3);
      border: 2px solid rgba(255, 255, 255, 0.08);
      border-radius: 16px;
      margin-bottom: 16px;
      text-align: left;

      .title {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 12px;
        color: #ffffff;
      }

      .content {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }
}

.statement_group {
  width: 1200px;
  margin: 0 auto 100px;
  background: rgba(11, 31, 58, 0.3);
  border-radius: 16px 16px 16px 16px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  padding: 24px;
  box-sizing: border-box;
  background-size: 110px;
  background-position: center right;
  margin-top: 80px;

  .tit {
    text-align: left;
    font-size: 20px;
    margin-bottom: 10px;
    color: #ffffff;
    text-align: center;
  }

  .desc {
    font-size: 14px;
    line-height: 24px;
  }
}
.img_qrcode_dialog {
  padding: 30px 0 50px;
}
.img_qrcode {
  width: 65%;
  padding: 30px;
  background-color: #eee;
  margin: 20px auto;
  border-radius: 20px;

  img {
    width: 100%;
    border-radius: 12px;
  }
}

.float_right {
  position: fixed;
  right: 10px;
  top: 40%;
  z-index: 2;
  background: linear-gradient(#123a94, #57298a);
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  font-size: 14px;
  color: #fff;
  padding: 36px 12px;
  transform: scale(0.76);
  box-shadow: 0 0 6px 0 rgb(132, 132, 132, 0.3);
  cursor: pointer;
  animation: rotateToRight 2s linear infinite;
  &:hover {
    animation: none;
    .step_block {
      display: block;
      &:extend(.translateX10-leave-active);
    }
  }
  .step_block {
    position: absolute;
    right: 95px;
    top: -125%;
    width: 300px;
    display: none;
    &:extend(.translateX10);
    &:extend(.translateX10-enter-active);
    .anticon {
      position: absolute;
      right: -16px;
      font-size: 30px;
      top: 336px;
    }
    .step_block_img {
      width: 100%;
    }
  }
}
.footer {
  background: #1c1c1c;
  color: #cecece;
  margin-top: 160px;
  > div {
    width: 1200px;
    margin: 0 auto;
    padding: 60px 0;
    .right {
      text-align: center;
      > div {
        width: 180px;
      }
      .qrcode {
        width: 125px;
        margin-bottom: 12px;
      }
      .text_box {
        width: 125px;
        border-radius: 30px;
        border: 1px solid rgba(255, 255, 255, 0.4);
        font-size: 12px;
        padding: 4px 0;
      }
    }
  }
  .copyright {
    padding-bottom: 60px;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    text-align: center;
    padding-top: 24px;
  }
}
</style>